@import '../../node_modules/intl-tel-input/build/css/intlTelInput.css';
@import 'theme';

body,
html {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iti {
  width: 100%;
}
