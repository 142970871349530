.sm-shown {
  display: none;
}

.md-shown {
  display: none;
}

.lg-shown {
  display: none;
}

.xl-shown {
  display: none;
}

@media (max-width: @screen-xl-max) {
  .xl-hidden {
    display: none;
  }

  .xl-shown {
    display: block;
  }
}

@media (max-width: @screen-lg-max) {
  .lg-hidden {
    display: none;
  }

  .lg-shown {
    display: block;
  }
}

@media (max-width: @screen-md-max) {
  .md-hidden {
    display: none;
  }

  .md-shown {
    display: block;
  }
}

@media (max-width: @screen-sm-max) {
  .sm-hidden {
    display: none;
  }

  .sm-shown {
    display: block;
  }
}
