@import '../../node_modules/ng-zorro-antd/ng-zorro-antd';
@import './colors.less';
@import './responsive.less';

@primary-color: @vvc-primary;
@text-color: @vvc-text-color;
@btn-primary-color: @vvc-text-color;
@btn-text-shadow: none;
@btn-primary-shadow: none;
@border-radius-base: 4px;
@border-color-base: @vvc-gray;
@input-hover-border-color: @vvc-primary-input;
@primary-color-hover: @vvc-primary-input;
@table-header-bg: @vvc-gray-table-header;
@table-border-color: @vvc-border-color;
@progress-default-color: @vvc-progress-color;
@primary-color-outline: @vvc-primary-input;
@outline-width: 0;
@secondary-color: @vvc-primary-input;
@header-height: 48px;
@footer-height: 40px;
@tabs-header-height: 46px;
@padding-xxl: 48px;
@padding-xl: 32px;
@margin-xxl: 48px;
@margin-xl: 32px;
@font-size-xl: @font-size-base + 6px;
@font-size-xxl: @font-size-base + 10px;
@text-selection-bg: @vvc-primary-input;
@item-hover-bg: @vvc-secondary-hover;

@vvc-initial-col-width-non-pixeled-sm: 350;
@vvc-initial-col-width-sm: (@vvc-initial-col-width-non-pixeled-sm * 1px);

@vvc-initial-col-width-non-pixeled-md: 250;
@vvc-initial-col-divided-width-non-pixeled-md: 496;
@vvc-initial-col-width-md: (@vvc-initial-col-width-non-pixeled-md * 1px);
@vvc-initial-col-divided-width-md: (@vvc-initial-col-divided-width-non-pixeled-md * 1px);

@vvc-initial-col-width-non-pixeled-lg: 288;
@vvc-initial-col-divided-width-non-pixeled-lg: 448;
@vvc-initial-col-width-lg: (@vvc-initial-col-width-non-pixeled-lg * 1px);
@vvc-initial-col-divided-width-lg: (@vvc-initial-col-divided-width-non-pixeled-lg * 1px);

@vvc-initial-col-width-non-pixeled-xl: 352;
@vvc-initial-col-divided-width-non-pixeled-xl: 544;
@vvc-initial-col-width-xl: (@vvc-initial-col-width-non-pixeled-xl * 1px);
@vvc-initial-col-divided-width-xl: (@vvc-initial-col-divided-width-non-pixeled-xl * 1px);

@vvc-initial-col-width-non-pixeled: 448;
@vvc-initial-col-divided-width-non-pixeled: 668;
@vvc-initial-col-width: (@vvc-initial-col-width-non-pixeled * 1px);
@vvc-initial-col-divided-width: (@vvc-initial-col-divided-width-non-pixeled * 1px);

@g-initial-height: 78px;

@vvc-font-xl-mobile: 18px;
@vvc-font-lg-mobile: 16px;

@vvc-container-max-width: 1408px;
@vvc-container-max-width-xl: 1120px;
@vvc-container-max-width-lg: 928px;
@vvc-container-max-width-md: 736px;
@vvc-container-max-width-sm: 608px;
@vvc-container-max-width-xs: 496px;
@vvc-container-max-width-xxs: 328px;

@layout-header-height-mobile: 48px;

.ant-btn {
  font-size: @font-size-base;
}

.ant-menu.submenu {
  box-shadow: @box-shadow-base;

  .menu-item a {
    display: block;
    width: 100%;
  }
}

.ant-input {
  font-size: @font-size-base;
  line-height: 1.796;
}

.ant-select-selection-placeholder {
  font-size: @font-size-base;
}

.ant-select-selection-item {
  font-size: @font-size-base;
}

.ant-input-affix-wrapper {
  .ant-input::placeholder {
    transition: color ease @animation-duration-base;
  }

  .ant-input-prefix {
    padding: 0 @padding-xss;
    color: @vvc-gray-darkest;
    transition: color ease @animation-duration-base;
  }
}

.ant-input-affix-wrapper-focused {
  .ant-input::placeholder {
    color: @vvc-primary-input;
  }

  .ant-input-prefix {
    color: @vvc-primary-input;
  }
}

a,
.ant-btn-link {
  color: @vvc-primary-input;

  &:hover {
    color: @vvc-primary-input;
  }
}

.ant-btn-link:hover {
  background-color: @vvc-gray-lightest;
}

.ant-pagination-item-active a {
  color: @vvc-primary-input;
}

.ant-tabs-ink-bar {
  background: @vvc-primary-input;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  padding: 0 @padding-lg;
  color: @vvc-primary-input;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: @vvc-table-hover;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: @padding-sm 0;
  border-bottom: 1px solid @vvc-border-color;
  border-radius: 0;
  font-weight: bold;
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0;
}

.user-management-content .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: @padding-md 0 0;
}

.user-management-content .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: @padding-xss;
  font-size: @font-size-xxl;
  transform: scaleX(-1) rotateZ(-90deg) translateX(50%);
}

.faq-content .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: @vvc-text-color;
  font-weight: bold;
}

.ant-form-item-control {
  flex: 1 0 100%;
}

.ant-form-item-label > label {
  flex-direction: row-reverse;
  height: 24px;

  &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-top: -4px;
    font-size: 10px;
  }

  &::after {
    margin: 0;
  }
}

.ant-list-item-meta-content {
  cursor: pointer;
}

.ant-pagination-item-active {
  border-color: @vvc-text-color;
}

.ant-list-item-meta-description {
  color: @vvc-text-color;
  font-weight: bold;
}

.ant-list-item-meta-title {
  color: @vvc-dark-gray;
  font-weight: normal;
}

.ant-table.ant-table-middle {
  .ant-table-thead > .ant-table-row > .ant-table-cell {
    padding: @table-padding-vertical @table-padding-horizontal;
    border-top: 1px solid @vvc-border-color;
    border-bottom: 1px solid @vvc-border-color;
    font-weight: bold;

    &::before {
      display: none;
    }
  }

  .ant-table-tbody > .ant-table-row > .ant-table-cell {
    padding: 6px @table-padding-horizontal;
  }
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 1011;
}

.ant-modal-body {
  padding: 0;
}

.menu {
  &-item {
    min-width: 186px;
    height: @height-base;
    margin: @margin-xss 0 !important;

    &:hover,
    &:focus,
    &:active {
      background-color: @vvc-secondary-hover;
      color: @secondary-color !important;

      a {
        background: transparent;
        color: @secondary-color !important;

        &:hover,
        &:focus,
        &:active {
          background: transparent;
          color: @secondary-color !important;
        }
      }

      .menu-icon {
        color: @secondary-color !important;
      }
    }

    &.ant-dropdown-menu-item-disabled {
      background: transparent !important;
      color: @vvc-gray !important;

      a {
        background: transparent !important;
        color: @vvc-gray !important;

        &:hover,
        &:focus,
        &:active {
          .menu-icon {
            color: @vvc-gray !important;
          }
        }
      }

      .menu-icon {
        color: @vvc-gray !important;
      }
    }
  }

  &-icon {
    margin-left: -@margin-xss;
    padding-right: @padding-xs;
    color: @vvc-gray-darkest;
    font-size: @font-size-lg;
    transition: color ease 0.3s;
  }
}

.ant-dropdown-menu-item-selected {
  background: transparent;
}
